import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import VisibilitySensor from 'react-visibility-sensor'
import { Element } from 'react-scroll'
import { useWindowSize, useMount } from 'react-use'

import { Layout, Block, DiningForm } from '../components'
import { container, padding, noPadding, colours } from '../styles/global'
import { parseACF } from '../utils'
import { media, useBreakpoint } from '../styles/utils'

import svgImage from '../assets/images/footer-graphic.svg'

const Index = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
	const [section, setSection] = useState('')
	const [isMobile, setIsMobile] = useState(false)
	const detectMobile = useBreakpoint('phone')
	const { width, height } = useWindowSize()

	useMount(() => {
        setIsMobile(detectMobile)
    })

    useEffect(() => {
        setIsMobile(detectMobile)
    }, [detectMobile])

    const toggleVisibility = (visible, section) => {
        if (visible) {
            setSection(section)
        }
    }

    return (
        <Layout meta={data && data.seo} footer={data.footer_text}>
            <Container>
                <Home id="home" height={height}>
                    <Block
                        layout={'image'}
                        display
                        image={data.header_image}
                        styles={HomeImage}
                        inline
                    />
                    <Block layout={'slider_news'} display news={data.news} />
                </Home>

                <About id="about">
                    <Block
                        layout={'text'}
                        display
                        paragraph={data.about_text}
                    />
                    <Block layout={'grid'} display grid={data.image_blocks} />
                </About>

				<Menu id="menu">
                    <Block
                        layout={'menu'}
                        display
                        text={isMobile ? data.menus_intro_mobile : data.menus_intro}
                        menus={data.menus}
                        date={data.menus_last_updated}
                    />
                </Menu>

                <Booking id="bookings">
                    <Block
                        layout={'text'}
                        display
                        padding={'small'}
                        paragraph={data.bookings_text}
                    />
                    <Block
                        layout={'button'}
                        display={data.bookings_show_link}
                        link={data.bookings_button_link}
                        text={data.bookings_button_text}
                    />
                </Booking>

                <Dining id="dining">
                    <Block
                        layout={'text'}
                        serif
                        display
                        padding={'small'}
                        paragraph={data.privatedining_intro}
                    />
                    <Block
                        layout={'button'}
                        display={data.privatedining_function_display}
                        link={data.privatedining_function_pdf}
                        text={'Download Function PDF'}
                    />
                    <Block
                        layout={'slider'}
                        display
                        slides={data.privatedining_carousel}
                    />
                    <Block
                        layout={'text'}
                        serif
                        padding={'100px 0 150px'}
                        display
                        paragraph={data.privatedining_booking_form_text}
                    />
					<DiningForm />
                </Dining>

                <Vouchers id="vouchers">
                    <Block
                        layout={'text'}
                        display
                        padding={'small'}
                        paragraph={data.vouchers_text}
                    />
                    <Block
                        layout={'button'}
                        display={data.vouchers_show_link}
                        link={data.vouchers_button_link}
                        text={data.vouchers_button_text}
                    />
                    <Block
                        layout={'image'}
                        display={data.compliments_image_display}
                        image={data.compliments_image}
                        styles={VoucherImage}
                        inline
                    />
                </Vouchers>
            </Container>
        </Layout>
    )
}

// Shared

const Wrapper = styled.div``
const Text = styled.div``
const Button = styled.div``
const Section = styled.section``
const Image = styled(ProgressiveImage)``
const InlineImage = styled.img``
const Meta = styled.div``
const Credits = styled.div``

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
	overflow: hidden;
`

// Home Section

const Home = styled.div`
    background: ${colours.pink};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${noPadding}
    position: relative;

    ${media.phone`
		/*height: ${(props) => props.height}px;*/
		height: 100%;
		min-height: calc(100vh - 110px);
	`}

    .block_slider_news {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        ${padding}
        padding-bottom: 40px;

        ${media.phone`
			padding-bottom: 50px;
		`}
    }
`

const HomeImage = css`
    width: 100%;
    max-width: 72vw;
    max-height: 82vh;
    pointer-events: none;
    user-select: none;
    padding-bottom: 0;
    object-fit: contain;

    ${media.tablet`
		max-width: 100%;
		padding: 0 8px;
	`}
`

// About Section

const About = styled(Element)`
    background: white;
    padding-bottom: 135px;

	${media.tablet`
		padding-bottom: 0;
	`}
`

// Menu Section

const Menu = styled.div`
    ${noPadding}
`

// Booking Section

const Booking = styled.div``

// Dining Section

const Dining = styled.div`
    background: ${colours.gold};
    ${noPadding}
	position: relative;

    .block_text, .block_button {
        ${media.phone`
			padding-left: 20px;
			padding-right: 20px;
		`}
    }
`

// Voucher Section

const Vouchers = styled.div`
    background: ${colours.white};
    width: 100%;

    ${media.phone`
		.block_button {
			padding-bottom: 50px;
		}
	`}
`

const VoucherImage = css`
    width: 100%;
    max-width: 990px;
    margin: 0 auto;
    pointer-events: none;
    user-select: none;
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
